<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              :deptIdArr="deptArr"
              v-model="parameter.deptId"
            />
          </span>
          <span
            >员工名称：
            <el-autocomplete
              class="ipt_width project_width"
              v-model="parameter.userName"
              :fetch-suggestions="userNameQuerySearch"
              placeholder="请输入或选择员工名称"
              @select="onProkectNameSelect"
              :popper-append-to-body="false"
              clearable
            >
            </el-autocomplete
          ></span>
          <span
            >项目名称：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请选择项目名称"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in projectList"
                :key="index"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>
          <span
            >商务名称：
            <el-select
              v-model="parameter.businessId"
              clearable
              filterable
              placeholder="请选择商务名称"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in businessList"
                :key="index"
                :label="item.businessName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>

          <span
            >工时类型：
            <el-select
              v-model="parameter.manhourType"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请选择工时类型"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in dictData.manhourType"
                :key="index"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option> </el-select
          ></span>
          <span
            >审核状态：
            <el-select
              v-model="parameter.checkStatus"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请选择审核状态"
              class="ipt_width"
            >
              <el-option
                v-for="(item, index) in dictData.manhourCheackStatus"
                :key="index"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option> </el-select
          ></span>

          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          <el-button
            type="primary"
            v-if="permission(['HOUR_LIST_EXPORT'])"
            icon="el-icon-printer"
            @click="exporting()"
            >导出</el-button
          >
          <el-button
            type="primary"
            style="width: 120px"
            icon="el-icon-left"
            v-if="status"
            @click="$router.back()"
            >返回上一页</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading"
            :key="Math.random()"
            @cell-click="handleCellClick"
          >
            <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="填报人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="所属部门"
              width="110"
            ></el-table-column>
            <el-table-column align="center" prop="workName" label="项目名称" min-width="240">
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">{{ scope.row.projectName }}</span>
                <span v-if="scope.row.manhourType == 1">{{ scope.row.workName }} </span>
                <span v-if="scope.row.manhourType == 2">{{ scope.row.businessName }} </span>
              </template></el-table-column
            >
            <el-table-column
              align="center"
              prop="projectTaskName"
              label="项目任务名称"
              min-width="220"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              min-width="240"
            ></el-table-column>

            <el-table-column align="center" prop="checkStatus" label="审批状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.checkStatus.toString() | dict(dictData.manhourCheackStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="checkEmployee" label="审批者" width="120">
            </el-table-column>
            <el-table-column
              align="center"
              prop="checkOpinion"
              label="审批意见"
              min-width="180"
            ></el-table-column>

            <el-table-column align="center" prop="manhourType" label="有无重要活动" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.outgoingType == 'HAVE'"
                  ><el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="showOutReportDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="isTraveling" label="是否外勤" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isTraveling == 'Y'"
                  ><el-button type="primary" size="mini" plain @click="showOutWorkDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column width="110" align="center" prop="manhourType" label="工时类型">
              <template slot-scope="scope">
                {{ scope.row.manhourType.toString() | dict(dictData.manhourType) }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="hour"
              label="填报工时"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="auditHour"
              label="审核工时"
              width="90"
            ></el-table-column>
            <el-table-column align="center" prop="manhourDate" label="工时日期" width="110">
              <template slot-scope="scope">
                {{ scope.row.manhourDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="submitDate"
              label="填报时间"
              width="160"
            ></el-table-column>
            <el-table-column
              v-if="isSpecial"
              prop="title"
              align="center"
              fixed="right"
              width="120"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  v-if="scope.row.checkStatus != 0 && scope.row.checkStatus != 3"
                  class="text_Remove_Bgc"
                  @click.stop="audit(scope.row, 0)"
                  >退回
                </el-button>
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  @click.stop="audit(scope.row, 1)"
                  >详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="工作内容" :visible.sync="workContentDialogVisible" width="40%">
      <div class="dialogContentHeight">{{ workContentDialog }}</div>
    </el-dialog>

    <OutReportDetail
      v-if="isOutReportDetailShow"
      :isOutReportDialogShow.sync="isOutReportDetailShow"
      :outReportData="manhourOutgoing"
    >
    </OutReportDetail>

    <EditDialog width="65%" :isShow.sync="showEditDialog" :loading.sync="editDialogLoading">
      <div slot="title" class="title">工时审批</div>
      <div slot="content" class="content">
        <div class="dg_title">工时日期：{{ dg_form.manhourDate }}</div>
        <!-- <div class="dg_title">填报日期：{{dg_form.submitDate}}</div> -->
        <el-form label-width="4em" :model="dg_form" ref="dg_form">
          <!-- YLZ开发审批内容 -->
          <el-descriptions :column="7" border direction="vertical">
            <!-- 第一行 -->
            <el-descriptions-item label="填报人">{{ dg_form.userName }}</el-descriptions-item>
            <el-descriptions-item label="填报部门">{{
              dg_form.departmentName
            }}</el-descriptions-item>
            <el-descriptions-item label="填报时间">{{ dg_form.submitDate }}</el-descriptions-item>
            <el-descriptions-item label="有无重要活动">
              <div>
                <div v-if="dg_form.outgoingType == 'HAVE'">
                  <span style="margin-right: 20px">有</span>
                  <el-button type="primary" size="mini" plain @click="isOutReportDetailShow = true"
                    >查看报告</el-button
                  >
                </div>
                <div v-else>无</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="是否外勤">
              <div>
                <div v-if="dg_form.isTraveling == 'Y'">
                  <span style="margin-right: 20px">是</span>
                  <el-button type="primary" size="mini" plain @click="isOutWorkDialogShow = true"
                    >查看报告</el-button
                  >
                </div>
                <div v-else>否</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="当天总上报工时">
              {{ zgs }}
            </el-descriptions-item>
            <el-descriptions-item label="上报工时" :span="1">
              {{ dg_form.hour }}
            </el-descriptions-item>
            <!-- 第二行 -->
            <el-descriptions-item
              label="项目名称"
              v-if="dg_form.manhourType == 0"
              :span="dg_form.transferStatus == 0 ? 3 : 2"
              >{{ dg_form.projectName }}
            </el-descriptions-item>
            <el-descriptions-item
              label="组织名称"
              v-if="dg_form.manhourType == 1"
              :span="dg_form.transferStatus == 0 ? 5 : 4"
            >
              {{ dg_form.deptName }}
            </el-descriptions-item>
            <el-descriptions-item
              label="商务名称"
              v-if="dg_form.manhourType == 2"
              :span="dg_form.transferStatus == 0 ? 5 : 4"
            >
              {{ dg_form.businessName }}
            </el-descriptions-item>
            <el-descriptions-item label="任务名称" v-if="dg_form.manhourType == 0" :span="2">
              {{ dg_form.projectTaskName }}
            </el-descriptions-item>
            <el-descriptions-item
              :contentClassName="
                dg_form.manhourType == 0
                  ? 'projectType'
                  : dg_form.manhourType == 1
                  ? 'deptType'
                  : 'businessType'
              "
              label="工时类型"
              :span="1"
            >
              {{
                dg_form.manhourType == 0 ? '生产级' : dg_form.manhourType == 1 ? '管理级' : '商务级'
              }}
            </el-descriptions-item>
            <template v-if="dg_form.transferStatus == 1">
              <el-descriptions-item label="转审人" :span="1">
                <template slot="label">
                  <span style="font-weight: 900; color: #f62a29; font-size: 15px">转审人</span>
                </template>
                <span style="font-weight: 900; color: #f62a29; font-size: 14px">{{
                  dg_form.transferName
                }}</span>
              </el-descriptions-item>
            </template>
            <el-descriptions-item label="审核工时" :span="1"
              >{{ dg_form.auditHour }}
            </el-descriptions-item>
            <!-- 第三行 -->
            <el-descriptions-item :span="7">
              <div slot="label">
                <span>工作内容</span>
              </div>
              <div class="workContentStyleOpen">
                {{ dg_form.workContent }}
              </div>
              <!-- <el-input type="textarea" :rows="3" readonly v-model="dg_form.workContent">
              </el-input> -->
            </el-descriptions-item>

            <!-- 第四行 -->
            <el-descriptions-item label="审批内容" :span="7" v-if="isDetail">
              <div id="audit-form-item">
                <el-form-item label="审批" prop="isCheckPass" ref="isCheckPass">
                  <el-radio-group v-model="dg_form.isCheckPass" disabled>
                    <el-radio :label="2">通过</el-radio>
                    <el-radio :label="3">不通过</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="说明" prop="checkOpinion" ref="checkOpinion">
                  <el-input
                    readonly
                    type="textarea"
                    v-model="dg_form.checkOpinion"
                    :autosize="{ minRows: 2, maxRows: 2 }"
                  ></el-input>
                </el-form-item>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="danger" :loading="loading" @click="showUnPassOpinion" v-if="!isDetail"
          >退回</el-button
        >
        <el-button type="warning" plain @click="showEditDialog = false">取消</el-button>
      </div>
    </EditDialog>

    <!-- 审核不通过意见框 -->
    <EditDialog :isShow.sync="unPassDialogVisible" @submit="submitUnPass" width="60%">
      <template v-slot:title>
        <span style="color: #409eff">{{
          dg_form.outgoingType == 'HAVE'
            ? dg_form.isTraveling == 'Y'
              ? '重要活动报告与外勤报告和管理员退回说明'
              : '重要活动报告与管理员退回说明'
            : dg_form.isTraveling == 'Y'
            ? '外勤报告与管理员退回说明'
            : '管理员退回说明'
        }}</span>
      </template>
      <div slot="content" class="content hour-audit" v-if="dg_form.outgoingType == 'HAVE'">
        <div class="out-report" style="min-height: 480px">
          <el-form
            v-if="manhourOutgoing"
            style="width: 100%"
            :model="manhourOutgoing"
            ref="outReportForm"
            label-width="107px"
            label-position="left"
          >
            <el-form-item label="报告类型" prop="type">
              <el-radio-group v-model="manhourOutgoing.type" disabled>
                <el-radio label="WRITTEN">书面报告</el-radio>
                <el-radio label="ORAL">口头报告</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="活动开始时间"
              prop="startDate"
              style="width: 50%; display: inline-block"
            >
              <el-date-picker
                v-model="manhourOutgoing.startDate"
                align="right"
                type="date"
                placeholder="选择日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="活动结束时间"
              prop="endDate"
              style="width: 50%; display: inline-block"
            >
              <el-date-picker
                v-model="manhourOutgoing.endDate"
                align="right"
                type="date"
                placeholder="选择日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="活动地点" prop="place">
              <el-input v-model.trim="manhourOutgoing.place" disabled></el-input>
            </el-form-item>
            <el-form-item :error="manhourOutgoing.fileErrMsg">
              <template slot="label">
                <template> 附件</template>
              </template>
              <div
                v-for="(n, index) in manhourOutgoing.manhourFileList"
                :key="index"
                style="margin-top: 5px"
                class="file-list"
              >
                <a
                  class="fj-text file-name"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
              </div>
            </el-form-item>
            <el-form-item label="活动说明" prop="content ">
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 9999 }"
                v-model.trim="manhourOutgoing.content"
                readonly
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center-btm">
          <el-form
            v-if="dg_form.isTraveling == 'Y'"
            style="width: 100%"
            :model="outWorkInfo"
            label-width="80px"
            label-position="left"
          >
            <el-form-item>
              <template slot="label"> 证明人 </template>
              <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                附件
                <el-tooltip class="item" effect="light" placement="top">
                  <div slot="content" style="font-size: 18px; color: #000">
                    工作派遣、聊天记录、通知函。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <div
                v-for="(n, index) in outWorkInfo.manhourFileList"
                :key="index"
                style="margin-top: 5px"
                class="file-list"
              >
                <a
                  class="fj-text file-name"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
              </div>
            </el-form-item>
            <el-form-item label="外勤说明" prop="content" style="width: 100%">
              <el-input
                type="textarea"
                v-model="outWorkInfo.content"
                readonly
                :autosize="{ minRows: 4, maxRows: 9999 }"
                placeholder="请输入外勤说明"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
            管理员退回说明
          </div>
          <el-form :model="dg_form" ref="form_d">
            <el-form-item label="" prop="opinion">
              <el-input
                placeholder="请填写管理员退回说明"
                v-model="dg_form.checkOpinion"
                @input="$forceUpdate()"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        slot="content"
        class="content hour-audit"
        v-else-if="dg_form.outgoingType != 'HAVE' && dg_form.isTraveling == 'Y'"
      >
        <div class="out-report" style="min-height: 480px">
          <el-form
            style="width: 100%"
            :model="outWorkInfo"
            label-width="80px"
            label-position="left"
          >
            <el-form-item>
              <template slot="label"> 证明人 </template>
              <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                附件
                <el-tooltip class="item" effect="light" placement="top">
                  <div slot="content" style="font-size: 18px; color: #000">
                    工作派遣、聊天记录、通知函。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <div
                v-for="(n, index) in outWorkInfo.manhourFileList"
                :key="index"
                style="margin-top: 5px"
                class="file-list"
              >
                <a
                  class="fj-text file-name"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
              </div>
            </el-form-item>
            <el-form-item label="外勤说明" prop="content" style="width: 100%">
              <el-input
                type="textarea"
                v-model="outWorkInfo.content"
                readonly
                :autosize="{ minRows: 4, maxRows: 9999 }"
                placeholder="请输入外勤说明"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center-btm">
          <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
            管理员退回说明
          </div>
          <el-form :model="dg_form" ref="form_d">
            <el-form-item label="" prop="opinion">
              <el-input
                placeholder="请填写管理员退回说明"
                v-model="dg_form.checkOpinion"
                @input="$forceUpdate()"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="content" class="content" v-else>
        <el-form :model="dg_form" ref="form_d">
          <el-form-item label="" prop="opinion">
            <el-input
              placeholder="请填写管理员退回说明"
              v-model="dg_form.checkOpinion"
              @input="$forceUpdate()"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <OutWorkDetail
      v-if="isOutWorkDialogShow"
      :isOutWorkDialogShow.sync="isOutWorkDialogShow"
      :outWorkInfo="outWorkInfo"
    />
  </div>
</template>
<script>
import { encrypt, decrypt } from '@/util/jsencrypt'
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    OutReportDetail: () => import('@/components/hour/out-report-detail.vue'),
    OutWorkDetail: () => import('@/components/hour/out-work-detail.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
        projectId: '',
        manhourType: '',
        userName: '',
        checkStatus: '',
        businessId: '',
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      createdDate: [],
      projectId: '',
      userId: '',
      deptId: '',
      status: '',
      deptList: [],
      is_dsz: '',
      projectList: [],
      workContentDialogVisible: false,
      workContentDialog: '',
      dictData: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },

      zgs: 0,
      isDetail: false,
      dg_form: {},
      showEditDialog: false,
      editDialogLoading: false,
      auditHour: '', //* 审核工时
      isAuditHourTipsShow: false,
      auditHourTips: '',
      workContentStyleClose: {},
      isWorkContentOpen: false,
      workContentDialogVisible: false,
      passDialogVisible: false,
      unPassDialogVisible: false,
      workContentDialog: '',
      orgObject: [],
      manhourOutgoing: null, //外出活动报告数据源
      isOutReportDetailShow: false,
      isOutReportDialogShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      deptProps: {
        children: 'children',
        label: 'deptName',
        value: 'id',
        checkStrictly: true,
        expandTrigger: 'hover',
      },
      outWorkInfo: {}, //外勤数据源
      isOutWorkDialogShow: false,
      userNameList: [],
      businessList: [],
      deptArr: [],
      isSpecial: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
          this.parameter.beginDate = ''
          this.parameter.endDate = ''
        }
      },
    },
    'parameter.deptId': {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getUserNameList(val)
        } else {
          this.getUserNameList()
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.is_dsz = JSON.parse(localStorage.getItem('userInfo'))
    this.getDictData()
    this.getData()
    this.getProject()
    this.get_DeptList()
  },
  methods: {
    getUserNameList(deptId = '') {
      this.$api.user
        .listStaff({ deptId, pageSize: 9999, pageNow: 1 })
        .then(res => {
          this.userNameList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    userNameQuerySearch(queryString, cb) {
      let userNameList = this.userNameList.map(item => {
        return { value: item.userName }
      })
      let results = queryString ? this.userNameCreateFilter(queryString) : userNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    userNameCreateFilter(queryString) {
      let results = this.userNameList.map(item => {
        return { value: item.userName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.userName = e.value
      }
      this.$forceUpdate()
    },
    getData() {
      this.loading = true
      // 这里的时间是输入的而不是传值的，手动给请求参数赋值
      if (this.createdDate && this.createdDate.length !== 0) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
        this.$route.query.createdDate = JSON.stringify(this.createdDate)
      }
      // 当时间是由传值传过来时
      if (this.$route.query.createdDate) {
        this.createdDate = JSON.parse(this.$route.query.createdDate)
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }
      if (this.$route.query.userId) {
        this.status = 1
        this.userId = decrypt(this.$route.query.userId)
        this.parameter.userId = this.userId
      }
      if (this.$route.query.projectId) {
        this.status = 2
        this.projectId = decrypt(this.$route.query.projectId)
        this.parameter.projectId = this.projectId
      }
      if (this.deptId && this.$route.query.deptId) {
        this.status = 3
        this.deptId = decrypt(this.$route.query.deptId)
        // this.parameter.deptId = this.deptId
        // } else if (this.is_dsz.postName == '董事长') {
      } else if (this.is_dsz.weight == 10000) {
        this.deptId = this.is_dsz.deptIdList[0]
        // this.parameter.deptId = this.deptId //组织架构调整，部门改为树形结构，董事长默认传参移除
        this.is_dsz = ''
      }
      if (this.$route.query.manhourType) {
        this.parameter.manhourType = decrypt(this.$route.query.manhourType)
      }
      if (this.$route.query.userName) {
        this.parameter.userName = this.$route.query.userName
      }
      if (this.$route.query.businessId) {
        this.parameter.businessId = decrypt(this.$route.query.businessId)
      }
      if (this.$route.query.deptId) {
        this.parameter.deptId = decrypt(this.$route.query.deptId)
      }
      if (this.$route.query.deptArr) {
        this.deptArr = JSON.parse(this.$route.query.deptArr)
      }

      this.$api.hour
        .mahhoursByUserList(this.parameter)
        .then(res => {
          this.isSpecial = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data?.manhourDataResponseIPage?.records
            this.isSpecial = res.data.isSpecial
            this.parameter.total = res.data?.manhourDataResponseIPage?.total
          }
          this.loading = false
          // 暂时移除 工时-全部明细中 退回按钮权限
          // this.tableData.forEach(item => {
          //   item.isShowBack = false
          //   if (item.isCheckPass != 2) {
          //     //说明未审核，不允许退回，显示详情
          //     item.isShowBack = false
          //   } else {
          //     //判断是否距今30天内
          //     let today = new Date() // 获取当前日期
          //     let inputDate = new Date(item.manhourDate) // 将传入的日期字符串转换为Date对象
          //     // 计算两个日期相差的天数
          //     var diffInTime = Math.abs(today - inputDate)
          //     var diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24))
          //     if (diffInDays <= 40) {
          //       item.isShowBack = true
          //     } else {
          //       item.isShowBack = false
          //     }
          //   }
          // })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 清理路由参数的方法
    clearRouteParams() {
      // 使用 replace 方法替换当前路由，并确保没有查询参数
      this.$router.replace({ path: '/hour/byUser/List', query: {} }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.clearRouteParams()
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.createdDate = []
      this.deptArr = []
      this.$route.query.createdDate = ''
      this.deptId = ''
      this.parameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
        businessId: '',
        manhourType: '',
        userName: '',
        checkStatus: '',
        userId: '',
        projectId: '',
      }
      this.clearRouteParams()
      this.getData()
    },
    get_DeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getProject() {
      this.$api.project
        .getProjectList({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.businessManage
        .getAllBusinessManageList({ pageSize: 10000 })
        .then(res => {
          if (!res.data) {
            this.businessList = []
          } else {
            this.businessList = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 打开预览工作内容
    handleCellClick(row, column) {
      if (column.label == '工作内容') {
        this.workContentDialog = row.workContent
        this.workContentDialogVisible = true
      }
    },
    getDictData() {
      this.$api.dict
        .listSysDictData('MANHOUR_TYPE', true)
        .then(res => {
          this.dictData.manhourType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('MAHHOURS_CHECK_STATUS', true)
        .then(res => {
          this.dictData.manhourCheackStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    exporting() {
      if (this.createdDate) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }

      this.$api.hour
        .mahhoursExpor(this.parameter)
        .then(res => {
          // 获取当前日期
          const date = new Date()
          const year = date.getFullYear().toString()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const timestamp = year + month + day

          const fileName = '工时明细数据' + timestamp + '.xlsx'

          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }

          this.$message({
            message: '导出成功!',
            type: 'success',
          })

          this.parameter.beginDate = ''
          this.parameter.endDate = ''
        })
        .catch(e => {})
    },

    showOutReportDetail(row) {
      this.manhourOutgoing = null
      // 需要调接口获取外出活动内容
      this.$api.hour
        .selectManhourOutgoingDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.manhourOutgoing = res.data
            this.isOutReportDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    showOutWorkDetail(row) {
      this.outWorkInfo = {}
      // 需要调接口获取外勤内容
      this.$api.hour
        .selectDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.outWorkInfo = res.data
            this.isOutWorkDialogShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    is_number() {
      this.auditHour = this.auditHour.replace(/[^\d.]/g, '')
    },

    audit(row, status) {
      this.auditHour = row.hour //审核工时默认为当前填报工时
      this.isAuditHourTipsShow = false
      this.auditHourTips = ''
      this.isWorkContentOpen = false
      this.manhourOutgoing = null
      if (row.manhourType == 1) {
        row.companyName = '组织-' + this.getObjectKey(row.companyType)
      }
      if (row.outgoingType == 'HAVE') {
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectManhourOutgoingDetailByManhourId(row.id)
          .then(res => {
            if (res.data) {
              this.manhourOutgoing = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }

      if (row.isTraveling == 'Y') {
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectDetailByManhourId(row.id)
          .then(res => {
            if (res.data) {
              this.outWorkInfo = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.dg_form = row
      this.showEditDialog = true
      this.getDaySum()
      if (status) {
        this.isDetail = true
      } else {
        this.isDetail = false
      }
      this.showEditDialog = true
    },

    getObjectKey(value) {
      const findResult = Object.keys(this.orgObject).find(key => this.orgObject[key] == value)
      if (findResult) {
        return findResult
      } else {
        return ''
      }
    },
    getDaySum() {
      let str = this.dg_form.manhourDate + '/' + this.dg_form.userId
      this.$api.hour
        .getDaySum(str)
        .then(res => {
          this.loading = false
          this.zgs = res.data
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/ //非负浮点数
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true
      } else {
        return false
      }
    },

    // 弹出审核不通过意见框
    showUnPassOpinion() {
      this.dg_form.checkOpinion = ''
      this.unPassDialogVisible = true
    },

    // 点击审核不通过
    submitUnPass() {
      // 审核通过赋状态值
      this.dg_form.isCheckPass = 3
      this.dg()
    },

    dg() {
      if (this.dg_form.isCheckPass == 3 && this.dg_form.checkOpinion == '') {
        this.$message('请填写退回说明！')
        return
      }
      this.dg_form.checkOpinion = '<管理员退回说明>' + '\n' + this.dg_form.checkOpinion
      this.editDialogLoading = true
      let arr = []
      this.dg_form.isCheckPass == 2
        ? (this.dg_form.checkStatus = 2)
        : (this.dg_form.checkStatus = 3)
      arr.push(this.dg_form)
      this.$api.hour
        .checkManhours(arr)
        .then(res => {
          this.getData()
          this.editDialogLoading = false
          this.passDialogVisible = false
          this.unPassDialogVisible = false
          this.showEditDialog = false
        })
        .catch(err => {
          console.log(err)
          this.editDialogLoading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
.dg_title {
  color: #333333;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.content {
  padding: 0 20px !important;
  .dg_title {
    margin-bottom: 5px !important;
  }
  .el-form {
    display: block !important;
    .descriptions {
      width: 100%;
    }
    .el-form-item {
      margin-bottom: 5px !important;
    }
  }
}

.el-link {
  font-size: 12px;
  margin-left: 20px;
}

.workContentStyleOpen {
  white-space: pre-line;
  width: 100%;
  height: 200px;
  overflow: auto;
}

.workContentStyleClose {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre;
}

.color_r {
  color: #f00;
  margin-top: 5px;
}

.hour-audit {
  display: flex;
  .out-report {
    // width: 50%;
    padding-right: 20px;
    border-right: 1px solid #efefef;
    flex: 1;
  }
  .text-center-btm {
    padding-left: 20px;
    width: 40%;
  }
}

.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/deep/.projectType {
  background-color: #a7f0f5;
}
/deep/.businessType {
  background-color: #f5a9a7;
}
/deep/.deptType {
  background-color: #a7f5a7;
}
</style>
